import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiGetAllBeetLogByVisitDateAndMemberId } from "../../../../api/beet-journey/beet-journey-logs";
import Swal from "sweetalert2";
import {
  getTimeFormat,
  formatDistance,
  convertSecondsToTimeFormat,
} from "../../../../helper/date-functions";
import { getStatusStyles } from "../../BeetJourneyReports";
import { exportToExcel } from "../hook";
import { useIsFMCG } from "../../../../helper/isManager";
import { apiGetTodayPatchJourney } from "../../../../api/patch-journey";

export const useBeetLogDetail = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [allLogs, setAllLogs] = useState([]);
  const [allDocLogs, setAllDocLogs] = useState([]);
  const isFMCG = useIsFMCG();
  const navigate = useNavigate();
  const refineData = (data) =>
    data.map((item) => ({
      beetName: item?.beet?.beet || "NA",
      address: item?.beet?.address || "NA",
      postalCode: item?.beet?.postalCode || "NA",
      outletName: item?.outletGetDto?.outletName || "NA",
      visitDate: item?.visitDate || "NA",
      orderRemark: item?.orderRemark
        ? item.orderRemark.split("_").join(" ")
        : "NA",
      workingType: item?.workingWith,
      workWith: item?.workingWithMemberDtoList
        ? item?.workingWithMemberDtoList
            ?.map((e) => `${e.memberName},`)
            ?.join(" ")
        : "N/A",
      checkIn: item?.checkIn || null,
      checkOut: item?.checkOut || null,
      totalTime: item?.totalTime || 0,
      totalDistance: item?.totalDistance || 0,
      beetJourneyPlanStatus:
        item?.beetJourneyPlanStatus || item?.doctorJourneyPlanStatus || "NA",
      doctorName: item?.doctorRes?.name,
    }));

  async function helperCall() {
    if (!state || !state?.memberName || !state.date) {
      window.location.href = "/";
      return;
    }
    setLoading(true);
    try {
      const apiResp = isFMCG
        ? await apiGetAllBeetLogByVisitDateAndMemberId(
            state.date,
            state.memberId
          )
        : await apiGetTodayPatchJourney(state.memberId, state.date);
      const refinedData = refineData(
        isFMCG ? apiResp : apiResp.bjpReportResponseList
      );
      setAllLogs(refinedData);
      if (!isFMCG) {
        const docRefineData = refineData(apiResp.doctorReportResponseList);
        setAllDocLogs(docRefineData);
      }
    } catch (error) {
      Swal.fire(
        "Something went wrong",
        error?.response?.data?.message ?? "Can't Fetch Necessary data"
      );
    }
    setLoading(false);
  }

  let templateCol = [
    {
      name: isFMCG ? "Beet Name" : "PATCH",
      selector: (row) => <span className="text-wrap">{row.beetName}</span>,
      sortable: true,
      width: "200px",
    },
    {
      name: "ADDRESS",
      selector: (row) => <span className="text-wrap">{row.address}</span>,
      sortable: true,
      width: "220px",
    },
    {
      name: "POSTAL CODE",
      selector: (row) => <span className="text-wrap">{row.postalCode}</span>,
      sortable: true,
      width: "140px",
    },
    {
      name: "VISIT DATE",
      selector: (row) => <span className="text-wrap">{row.visitDate}</span>,
      sortable: true,
      width: "150px",
    },
    {
      name: "ORDER REMARK",
      selector: (row) => <span className="text-wrap">{row.orderRemark}</span>,
      sortable: true,
      width: "200px",
    },
    {
      name: "WORKING TYPE",
      selector: (row) => <span className="text-wrap">{row.workingType}</span>,
      sortable: true,
      width: "150px",
    },
    {
      name: "WORKING WITH",
      selector: (row) => <span className="text-wrap">{row.workWith}</span>,
      sortable: true,
      width: "150px",
    },
    {
      name: "CHECKED IN",
      selector: (row) => (
        <span className="text-wrap">
          {row.checkIn ? getTimeFormat(row.checkIn) : "NA"}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "CHECKED OUT",
      selector: (row) => (
        <span className="text-wrap">
          {row.checkOut ? getTimeFormat(row.checkOut) : "NA"}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "TOTAL TIME",
      selector: (row) => convertSecondsToTimeFormat(row.totalTime),
      sortable: true,
      width: "150px",
    },
    {
      name: "TOTAL DISTANCE",
      selector: (row) => (
        <span className="text-wrap">{formatDistance(row.totalDistance)}</span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "JOURNEY STATUS",
      selector: (row) => (
        <span className="text-wrap">{row.beetJourneyPlanStatus}</span>
      ),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <p
          style={{
            marginTop: "1em",
            ...getStatusStyles(row.beetJourneyPlanStatus),
            fontSize: 13,
            borderTopLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          {row.beetJourneyPlanStatus}
        </p>
      ),
    },
  ];

  let chemistCol = [...templateCol];
  chemistCol.splice(3, 0, {
    name: isFMCG ? "OUTLET" : "CHEMIST",
    selector: (row) => <span className="text-wrap">{row.outletName}</span>,
    sortable: true,
    width: "150px",
  });

  let docCol = [...templateCol];
  docCol.splice(3, 0, {
    name: "DOC NAME",
    selector: (row) => <span className="text-wrap">{row.doctorName}</span>,
    sortable: true,
    width: "250px",
  });

  useEffect(() => {
    helperCall();
  }, []);

  return {
    loading,
    employeeName: state?.memberName ?? "",
    exportToExcel: (isDoc) =>
      exportToExcel(
        isDoc ? allDocLogs : allLogs,
        isDoc ? docCol : chemistCol,
        `${isDoc ? "DOC-" : "CHEMIST_"}${state?.date}-report`,
        `${state?.memberName}-${isDoc ? "DOC-" : "CHEMIST_"}${
          state?.date
        }-report.xlsx`
      ),
    columns: chemistCol,
    docColumns: docCol,
    allLogs,
    allDocLogs,
    isFMCG,
    navigate,
  };
};
