import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMemberHook } from "../../../hooks/memberHook";

function MemberDataTable() {
  const navigate = useNavigate();
  const Member = useSelector((state) => state.Member);
  const { get } = useMemberHook();

  useEffect(() => {
    if (Member.allMembers.length <= 0) {
      get();
    }
  }, []);

  const MemberCols = [
    {
      name: <span className="text-wrap">EMPLOYEE ID</span>,
      selector: (row) => (
        <span className="text-wrap">{row?.employeeId || "NA"}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="text-wrap">MEMBER NAME</span>,
      selector: (row) => (
        <strong
          onClick={() => navigate(`/member-schedule-plan/${row.id}`)}
          className="text-primary"
          style={{
            textDecorationLine: "underline",
            cursor: "pointer",
          }}
        >
          {`${row.firstName} ${row.lastName}` || "No details"}
        </strong>
      ),
      sortable: true,
    },
    {
      name: <span className="text-wrap">DIVISION</span>,
      selector: (row) => (
        <div>
          {row?.divisions?.map((item) => (
            <span className={"text-wrap"}>
              {item.divisionName?.toUpperCase() || "No details"}{" "}
            </span>
          )) || "No division assigned"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <span className="text-wrap">CITIES</span>,
      selector: (row) => (
        <div>
        {row?.cities?.map((item) => (
          <span className={"text-wrap"}>
            {item.cityName || "No details"}{" "}
          </span>
        )) || "No city assigned"}
      </div>
      ),
      sortable: true,
    },
    {
      name: <span className="text-wrap">CONTACT</span>,
      selector: (row) => (
        <span className="text-wrap">{row?.mobile || "NA"}</span>
      ),
      sortable: true,
    },
  ];

  return (
    <div className="row clearfix g-3">
      <div className="col-lg-12 col-md-10 flex-column mt-n20">
        {Member.allMembers.length > 0 ? (
          <DataTable
            columns={MemberCols}
            data={Member.allMembers}
            pagination
            paginationServer
            paginationTotalRows={Member.allMembers.length}
            highlightOnHover
            responsive
            progressComponent={
              <div className="text-center py-3">
                <Spinner animation="border" size="lg" role="status" />
                <p>Loading members...</p>
              </div>
            }
            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
            customStyles={{
              headCells: {
                style: {
                  backgroundColor: "#f1f5f9",
                  fontWeight: "bold",
                },
              },
              rows: {
                style: {
                  minHeight: "41px",
                },
              },
            }}
          />
        ) : (
          <div className="background-color: #3498db; color: #fff; text-align: center; padding: 10px;">
            <p className="font-size: 18px; font-weight: bold;">
              No Members available.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MemberDataTable;
