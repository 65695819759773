import { useSelector } from "react-redux";

export const CLIENT_TYPE = window.localStorage.getItem("CLIENT_TYPE");

export default function useClientFMCGMemberMenu() {
  const CredId = useSelector((state) => state.Cred.sub);

  const ClientFMCGSuperAdminMenu = [
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Member Map",
          routerLink: [""],
          identifier: "membermap",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Members",
          routerLink: [`member/${CredId}`],
          identifier: "members",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Attendance",
          routerLink: [`attendance/${CredId}`],
          identifier: "Attendance",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Leave Requests",
          routerLink: [`leave-request/${CredId}`],
          identifier: "leave-request",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Our Clients",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Clients (FMCG)" : "Clients",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`clientsFmcg/${CredId}`]
              : [`doctors/${CredId}`],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Beats" : "Routes",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`beets/${CredId}`]
              : [`routes/${CredId}`],
          identifier: "beets",
          iconClass: "icofont-location-pin",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
      ],
    },
    {
      name: "Accounts",
      routerLink: [""],
      identifier: "Accounts",
      iconClass: "icofont-ui-calculator",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Expenses",
          routerLink: [`expenses/${CredId}`],
          identifier: "Expenses",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
  ];

  const ClientFMCGMemberMenu = [
    {
      name: "Sales",
      routerLink: [""],
      identifier: "Sales",
      iconClass: "icofont-chart-histogram",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Primary Sales",
          routerLink: [`primarySales/${CredId}`],
          identifier: "Primary Sales",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Secondary Sales",
          routerLink: [`secondarySales/${CredId}`],
          identifier: "Secondary Sales",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Inventory",
      routerLink: [""],
      identifier: "Products",
      iconClass: "icofont-box",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Inventory",
          routerLink: ["inventory"],
          identifier: "Inventory",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Member Map",
          routerLink: [""],
          identifier: "membermap",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Members",
          routerLink: [`member/${CredId}`],
          identifier: "members",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Attendance",
          routerLink: [`attendance/${CredId}`],
          identifier: "Attendance",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Leave Requests",
          routerLink: [`leave-request/${CredId}`],
          identifier: "leave-request",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Our Clients",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Clients (FMCG)" : "Doctors",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`clientsFmcg/${CredId}`]
              : [`doctors/${CredId}`],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Beats" : "Routes",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`beets/${CredId}`]
              : [`routes/${CredId}`],
          identifier: "beets",
          iconClass: "icofont-location-pin",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
      ],
    },
    {
      name: "Accounts",
      routerLink: [""],
      identifier: "Accounts",
      iconClass: "icofont-ui-calculator",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Expenses",
          routerLink: [`expenses/${CredId}`],
          identifier: "Expenses",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
  ];

  const ProductMemberMenu = [
    {
      name: "Products",
      routerLink: [""],
      identifier: "Master",
      iconClass: "icofont-ui-settings",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Product",
          routerLink: ["productmaster"],
          identifier: "Product Master",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
  ];

  return {
    CLIENT_TYPE,
    ClientFMCGMemberMenu,
    ClientFMCGSuperAdminMenu,
    ProductMemberMenu,
  };
}
