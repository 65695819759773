import React, { useState ,useEffect } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { permissionIds } from "../../../constants/constants";
import PageHeader from "../../../components/common/PageHeader";
import { getAllMembersWithHierarchy } from "../../../api/member/member-api";



const AssignPermission = () => {
  const { memberPermissions } = useSelector((state) => state.Permission);
  const Cred = useSelector((state) => state.Cred);
  //console.log("Cred",Cred.id)

  // Predefined permission pages
  const predefinedPermissions = [
    "Manager",
    "Beet",
    "Expense",
    "Leave",
    "Outlet",
    "Product",
  ];

  const permissionsData = predefinedPermissions.map((permission, index) => ({
    id: index + 1,
    name: permission,
    description: `Description for ${permission}`,
    create: false,
    edit: false,
    delete: false,
    view: false,
  }));

  const [data, setData] = useState(permissionsData);

  const hasSuperAdminPermission = memberPermissions?.some(
    (permission) => permission === permissionIds.SUPER_ADMIN
  );
 
//   const allMember= async()=>{
//     const resp = await getAllMembersWithHierarchy (Cred.id ,Cred.token)
//     console.log(resp)
// }
// useEffect(()=>{
//     allMember()
// },[])
 

   
   
  if (!hasSuperAdminPermission) {
    return (
      <div className="container-xxl text-center mt-5">
        <h3>You do not have the required permission to access this page.</h3>
      </div>
    );
  }

  const handleCheckboxChange = (e, rowId, action) => {
    const updatedData = data.map((row) =>
      row.id === rowId ? { ...row, [action]: e.target.checked } : row
    );
    setData(updatedData);
  };

  const handleSave = () => {
    console.log("Changes saved:", data);
  };

  const handleCancel = () => {
    setData(permissionsData);
  };

  const columns = [
    {
      name: "Permission Page",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Create",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.create}
          onChange={(e) => handleCheckboxChange(e, row.id, "create")}
        />
      ),
    },
    {
      name: "Edit",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.edit}
          onChange={(e) => handleCheckboxChange(e, row.id, "edit")}
        />
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.delete}
          onChange={(e) => handleCheckboxChange(e, row.id, "delete")}
        />
      ),
    },
    {
      name: "View",
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.view}
          onChange={(e) => handleCheckboxChange(e, row.id, "view")}
        />
      ),
    },
  ];

  return (
    <div className="container-xxl">
      <PageHeader headerTitle="Assign Permission" />
      <div className="row clearfix g-3">
        <div className="card">
          <div className="card-body">
            <DataTable
              title="Assigned Permissions"
              columns={columns}
              data={data}
              pagination
              highlightOnHover
              dense
            />
            {/* Save and Cancel Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-secondary me-2" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignPermission;






//   const allMember= async()=>{
//     const resp = await getAllMembersWithHierarchy (Cred.id ,Cred.token)
//     console.log(resp)
// }
// useEffect(()=>{
//     allMember()
// },[])
 