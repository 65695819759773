import React, { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import Avatar1 from "../../assets/images/xs/avatar1.jpg";
import Avatar2 from "../../assets/images/xs/avatar2.jpg";
import Avatar3 from "../../assets/images/xs/avatar3.jpg";
import Avatar4 from "../../assets/images/xs/avatar4.jpg";
import Avatar5 from "../../assets/images/xs/avatar5.jpg";
import Avatar6 from "../../assets/images/xs/avatar6.jpg";
import Avatar7 from "../../assets/images/xs/avatar7.jpg";
import Avatar8 from "../../assets/images/xs/avatar8.jpg";
import ProfileImg from "../../assets/images/profile_av.png";
import { Link, useNavigate } from "react-router-dom";
import { constants } from "../../constants/constants";
import AddNewUserModal from "./AddNewUserModal";
import { useDispatch, useSelector } from "react-redux";
// import searchPages from "../Data/searchPages.json";

import { deleteCredentials } from "../../redux/features/credentialSlice";
import { deleteAllClients } from "../../redux/features/clientSlice";
import { deleteAllDropDownMembers } from "../../redux/features/dropdownMemberSlice";
import { deleteAllDropDowns } from "../../redux/features/dropdownClientSlice";
import { deleteAllExpenses } from "../../redux/features/expenseSlice";
import { deleteAllHoliday } from "../../redux/features/holidaySlice";
import { deleteAllLeaveRequests } from "../../redux/features/leaveRequestSlice";
import { deleteAllLeaves } from "../../redux/features/leavesSlice";
import { deleteAllMembers } from "../../redux/features/memberSlice";
import { deleteAllPrimarySale } from "../../redux/features/primarySalesSlice";
import { deleteAllProducts } from "../../redux/features/productsSlice";
import { deleteAllSecondarySales } from "../../redux/features/secondarySalesSlice";
import { deleteAllSchedules } from "../../redux/features/schedulesSlice";
import { deleteAllMemberPermissions } from "../../redux/features/permissionSlice";
import { useIsClient } from "../../helper/isManager";

function Header() {
  const [isAddUserModa, setIsAddUserModa] = useState(false);
  const Cred = useSelector((state) => state.Cred);
  const { memberPermissions } = useSelector((state) => state.Permission);
  // const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedPage, setSelectedpage] = useState("");
  const isClient = useIsClient();
  const [isModal, setIsModal] = useState(false);
  const Dispatch = useDispatch();
  const history = useNavigate();
  const handleIsModal = () => {
    setIsModal(!isModal);
  };
  // const toggleDropdownSearch = () => {
  //   setIsSearchOpen(!isSearchOpen);
  // };
  const onChange = (e) => {
    setSelectedpage(e.target.value);
  };
  // const onSearch = (searchTerm) => {
  //   setIsSearchOpen(false);

  //   setSelectedpage("");
  // };
  return (
    <div className="header">
      <nav className="navbar py-4">
        <div className="container-xxl">
          <div className="h-right d-flex align-items-center order-1">
            {!isClient && (
              <div className="d-flex">
                <button className="btn btn-primary" onClick={handleIsModal}>
                  My Permissions
                </button>
              </div>
            )}
            <Dropdown className="dropdown user-profile ms-2 ms-sm-3 d-flex align-items-center">
              <div className="u-info me-2">
                <p className="mb-0 text-end line-height-sm ">
                  {Cred.firstName && (
                    <span className="font-weight-bold">
                      {Cred.firstName + " " + Cred.lastName}
                    </span>
                  )}
                  {!Cred.firstName && (
                    <span className="font-weight-bold">
                      {Cred.clientFirstName + " " + Cred.clientLastName}
                    </span>
                  )}
                  {Cred.topUpBalance && isClient && (
                    <div className="font-weight-bold text-start my-1">
                      ₹{" "}
                      <span className="text-success">
                        {Cred.topUpBalance?.toFixed(2)}
                      </span>
                    </div>
                  )}
                </p>
                {Cred.firstName && <small>Profile</small>}
              </div>
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle pulse p-0"
              >
                {Cred.uploadFileKey ? (
                  <img
                    style={{
                      cursor: "pointer",
                    }}
                    className="avatar lg rounded-circle img-thumbnail"
                    src={`https://prismsfa-bucket.s3.ap-south-1.amazonaws.com/${Cred.uploadFileKey}`}
                    alt="profile"
                  />
                ) : (
                  <img
                    style={{
                      cursor: "pointer",
                    }}
                    className="avatar lg rounded-circle img-thumbnail"
                    src={ProfileImg}
                    alt="profile"
                  />
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                <div className="card border-0 w280">
                  <div className="card-body pb-0">
                    <div className="d-flex py-1">
                      {Cred.uploadFileKey ? (
                        <img
                          className="avatar rounded-circle"
                          src={`https://prismsfa-bucket.s3.ap-south-1.amazonaws.com/${Cred.uploadFileKey}`}
                          alt="profile"
                        />
                      ) : (
                        <img
                          className="avatar rounded-circle"
                          src={ProfileImg}
                          alt="profile"
                        />
                      )}
                      <div className="flex-fill ms-3">
                        <p className="mb-0">
                          {Cred.firstName ? (
                            <span className="font-weight-bold">
                              {Cred.firstName + " " + Cred.lastName}
                            </span>
                          ) : (
                            <span className="font-weight-bold">
                              {Cred.clientFirstName + " " + Cred.clientLastName}
                            </span>
                          )}
                        </p>
                        <small className="">{Cred.email}</small>
                      </div>
                    </div>

                    <div>
                      <hr className="dropdown-divider border-dark" />
                    </div>
                  </div>
                  <div className="list-group m-2 ">
                    {Cred.topUpBalance && (
                      <div className="list-group-item list-group-item-action border-0 ">
                        <i class="icofont-rupee fs-5 me-3 "></i>
                        <span className="text-success font-weight-bold fs-5">
                          {Cred.topUpBalance?.toFixed(2)}
                        </span>
                      </div>
                    )}
                    <Link
                      to={Cred.firstName ? "member-profile" : "client-profile"}
                      className="list-group-item list-group-item-action border-0 "
                    >
                      <i className="icofont-user fs-5 me-3"></i>View Profile
                    </Link>
                    {Cred.employeeId && (
                      <>
                        <Link
                          to="-scheduler"
                          className="list-group-item list-group-item-action border-0 "
                        >
                          <i className="icofont-tasks fs-5 me-3"></i>Schedules
                        </Link>
                        <Link
                          to={`/member/${Cred.sub}`}
                          className="list-group-item list-group-item-action border-0 "
                        >
                          <i className="icofont-ui-user-group fs-5 me-3"></i>
                          Members
                        </Link>
                      </>
                    )}
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        Dispatch(deleteCredentials());
                        Dispatch(deleteAllClients());
                        Dispatch(deleteAllDropDownMembers());
                        Dispatch(deleteAllDropDowns());
                        Dispatch(deleteAllExpenses());
                        Dispatch(deleteAllHoliday());
                        Dispatch(deleteAllLeaveRequests());
                        Dispatch(deleteAllLeaves());
                        Dispatch(deleteAllMembers());
                        Dispatch(deleteAllPrimarySale());
                        Dispatch(deleteAllProducts());
                        Dispatch(deleteAllSecondarySales());
                        Dispatch(deleteAllSchedules());
                        Dispatch(deleteAllMemberPermissions());
                        history("/");
                        window.localStorage.removeItem(constants.token_store);
                        window.localStorage.removeItem(constants.base_url);
                        window.localStorage.removeItem(constants.clientType);
                        window.localStorage.removeItem(constants.tenant_Id);
                        window.localStorage.removeItem("isMember");
                        window.localStorage.removeItem("primary-sale-draft");
                        window.localStorage.removeItem("secondary-sale-draft");
                        window.location.reload();
                      }}
                      className="list-group-item list-group-item-action border-0 "
                    >
                      <i className="icofont-logout fs-6 me-3"></i>Signout
                    </div>
                    <div>
                      <hr className="dropdown-divider border-dark" />
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <button
            className="navbar-toggler p-0 border-0 menu-toggle order-3"
            onClick={() => {
              var side = document.getElementById("mainSideMenu");
              if (side) {
                if (side.classList.contains("open")) {
                  side.classList.remove("open");
                } else {
                  side.classList.add("open");
                }
              }
            }}
          >
            <span className="fa fa-bars"></span>
          </button>
          <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">
            <div className="input-group flex-nowrap input-group-lg">
              {/* <Dropdown show={isSearchOpen} className="d-inline-flex m-1">
                <Dropdown.Toggle as="a" variant="" id="dropdown-basic">
                  <input
                    type="text"
                    value={selectedPage}
                    className="form-control"
                    id="dropdown-basic"
                    onChange={onChange}
                    onClick={toggleDropdownSearch}
                    placeholder="Search"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu
                  as="ul"
                  className="border-0 shadow bg-light-dark"
                >
                  {/* {searchPages
                    .filter((item) => {
                      const searchedPage = selectedPage.toLocaleLowerCase();
                      const pageName = item.name.toLocaleLowerCase();
                      return pageName.startsWith(
                        searchedPage.toLocaleLowerCase()
                      );
                    })
                    .map((e) => (
                      <ul style={{ listStyleType: "none" }}>
                        <li onClick={() => onSearch(e.name)}>
                          <Link to={process.env.PUBLIC_URL + e.routerLink}>
                            {e.name}
                          </Link>
                        </li>
                      </ul>
                    ))} */}

              {/* <ul style={{ listStyleType: "none" }}>
                    {searchPages
                      .filter((item) => {
                        const searchedPage = selectedPage.toLowerCase();
                        const pageName = item.name.toLowerCase();
                        return pageName.startsWith(searchedPage);
                      })
                      .map((e, index) => (
                        <li key={index} onClick={() => onSearch(e.name)}>
                          <Link to={process.env.PUBLIC_URL + e.routerLink}>
                            {e.name}
                          </Link>
                        </li>
                      ))}
                  </ul> */}
              {/* </Dropdown.Menu> */}
              {/* </Dropdown> */}
              {/* 
              <button
                type="button"
                className="input-group-text add-member-top"
                onClick={() => {
                  setIsAddUserModa(true);
                }}
              >
                <i className="fa fa-info"></i>
              </button> */}

              <Modal
                show={isModal}
                top
                size="sm"
                onHide={() => {
                  handleIsModal();
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="fw-bold">My Permission</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="table-responsive">
                    <table className="custom-table">
                      <thead></thead>
                      <tbody>
                        {memberPermissions.map((item, index) => (
                          <tr
                            key={index}
                            className={index % 2 === 0 ? "darker-row" : ""}
                          >
                            <td className="fw-bold">{item}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </nav>
      <AddNewUserModal
        show={isAddUserModa}
        onClose={() => {
          setIsAddUserModa(false);
        }}
      />
    </div>
  );
}

export default Header;
