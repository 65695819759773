import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useMemberMenu from "../Data/MemberMenu";
import useMasterMenu from "../Data/MasterMenu";
import { MasterMenu } from "../Data/MasterMenu";
import { ClientFMCGMenu } from "../Data/ClientFMCGMenu";
import { constants, permissionIds } from "../../constants/constants";
import { useIsManager, useIsSuperAdmin } from "../../helper/isManager";
import useClientFMCGMemberMenu from "../Data/ClientFMCGMemberMenu";

function Sidebar(props) {
  const [isSidebarMini, setIsSidebarMini] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [darkLightMode, setDarkLightMode] = useState("light");
  // const [updateRtl, setUpdateRtl] = useState(false);
  const {
    MemberMenu
  } = useMemberMenu();

  const {
    MasterMenu
  } = useMasterMenu();

  const {
    CLIENT_TYPE,
    ClientFMCGMemberMenu,
    ClientFMCGSuperAdminMenu,
    ProductMemberMenu
  } = useClientFMCGMemberMenu()

  const isMember = window.localStorage.getItem("isMember");
  const isClientFmcg = window.localStorage.getItem(constants.clientType)
  const Permission = useSelector((state) => state.Permission.memberPermissions);
  // const isManager = useIsManager();
  const isSuperAdmin = useIsSuperAdmin()
  const history = useNavigate();
  
  useEffect(() => {
    if (isMember === "false") {
      setMenuData([...ClientFMCGMenu]);
    } 
    else if (isMember === "true" && isClientFmcg === "CLIENT_FMCG") {
      if (isSuperAdmin) {
        setMenuData([...MasterMenu, ...ClientFMCGSuperAdminMenu]);
      } else {
        setMenuData([...ProductMemberMenu, ...ClientFMCGMemberMenu]);
      }
    } 
    else if (isMember === "true" && isClientFmcg !== "CLIENT_FMCG") {
      if (isSuperAdmin) {
        setMenuData([...MasterMenu, ...MemberMenu]);
      } else {
        setMenuData([...ProductMemberMenu, ...MemberMenu]);
      }
    }
    document.documentElement.setAttribute("data-theme", "light");
  }, [isMember, Permission]);

  function openChildren(id) {
    const otherTabs = document.getElementsByClassName("has-children");
    Array.from(otherTabs).forEach((tab) => {
      if (tab.id !== id) {
        tab.classList.remove("show");
        if (tab.parentElement.children.length > 1) {
          tab.parentElement.children[0].setAttribute("aria-expanded", "false");
        }
      }
    });

    const menutab = document.getElementById(id);
    if (menutab) {
      if (menutab.classList.contains("show")) {
        menutab.classList.remove("show");
        menutab.parentElement.children[0]?.setAttribute(
          "aria-expanded",
          "false"
        );
      } else {
        menutab.classList.add("show");
        menutab.parentElement.children[0]?.setAttribute(
          "aria-expanded",
          "true"
        );
      }
    }
  }

  function openChildren1(id) {
    const otherTabs = document.getElementsByClassName("has-children");
    Array.from(otherTabs).forEach((tab) => tab.classList.remove("show"));

    const menutab = document.getElementById(id);
    if (menutab) {
      menutab.classList.add("show");
      menutab.parentElement.children[0]?.setAttribute("aria-expanded", "true");
    }
  }

  function GotoChangeMenu(val) {
    if (val === "UI Components") {
      history.push("ui-alerts");
      setMenuData([...ClientFMCGMenu]);
    } else {
      history.push("hr-dashboard");
      setMenuData([...MasterMenu]);
    }
  }

  function onChangeDarkMode() {
    const theme = document.documentElement.getAttribute("data-theme");
    if (theme === "light") {
      document.documentElement.setAttribute("data-theme", "dark");
      setDarkLightMode("dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      setDarkLightMode("light");
    }
  }

  const { activekey } = props;

  return (
    <div
      id="mainSideMenu"
      className={`sidebar px-4 py-4 py-md-5 me-0 ${
        isSidebarMini ? "sidebar-mini" : ""
      }`}
    >
      <div className="d-flex flex-column h-100">
        <a href="/" className="mb-0 brand-icon">
          <span className="logo-icon">
            <img
              style={{
                width: 80,
                height: 80,
                alignSelf: "center",
                padding: 10,
              }}
              src={require("../../assets/images/logo.png")}
              alt="Logo"
            />
          </span>
          <span className="logo-text">{constants.website_name}</span>
        </a>
        <ul className="menu-list flex-grow-1 mt-3">
          {menuData.map((d, i) => (
            <li key={i} className="collapsed">
              {d.children.length > 0 ? (
                <>
                  <a
                    className={`m-link ${
                      d.children.some(
                        (child) => `/${child.routerLink[0]}` === activekey
                      )
                        ? "active"
                        : ""
                    }`}
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      openChildren(`menu-Pages${i}`);
                    }}
                  >
                    <i className={d.iconClass}></i>
                    <span>{d.name}</span>
                    <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                  </a>
                  <ul
                    className="sub-menu collapse has-children"
                    id={`menu-Pages${i}`}
                  >
                    {d.children.map((child, ind) => (
                      <li key={ind}>
                        <Link
                          className={`ms-link ${
                            activekey === `/${child.routerLink[0]}`
                              ? "active"
                              : ""
                          }`}
                          to={`/${child.routerLink[0]}`}
                        >
                          {child.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link
                  to={`/${d.routerLink[0]}`}
                  className={`m-link ${
                    activekey === `/${d.routerLink[0]}` ? "active" : ""
                  }`}
                >
                  <i className={d.iconClass}></i>
                  <span>{d.name}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>
        <button
          type="button"
          className="btn btn-link sidebar-mini-btn text-light"
          onClick={() => setIsSidebarMini(!isSidebarMini)}
        >
          <span className="ms-2">
            <i className="icofont-bubble-right"></i>
          </span>
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
