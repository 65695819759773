export const constants = {
  token_store: "PRISM_SFA_JWT_TOKEN",
  tenant_Id: "SFA_TENANT_ID",
  website_name: "Prism-SFA",
  google_map_api_key: "AIzaSyAr1KWVTFxA9h_v8NUhgNV2JJxnGxHXArc",
  base_url: "BASE_URL",
  clientType: "CLIENT_TYPE"
};

export const permissionIds = {
  SUPER_ADMIN: "Super_Admin",
  REPORTING_MANAGER: "Reporting_Manager",
  MANAGER: "Manager",
  CREATE_MANAGER: "Create_Manager",
  EDIT_MANAGER: "Edit_Manager",
  DELETE_MANAGER: "Delete_Manager",
  VIEW_MANAGER: "View_Manager",
  CLIENT_FMCG: "ClientFMCG",
  CLIENT: "Client",
  CREATE_BEET: "Create_Beet",
  EDIT_BEET: "Edit_Beet",
  DELETE_BEET: "Delete_Beet",
  VIEW_BEET: "View_Beet",
  CREATE_EXPENSE: "Create_Expense",
  EDIT_EXPENSE: "Edit_Expense",
  DELETE_EXPENSE: "Delete_Expense",
  VIEW_EXPENSE: "View_Expense",
  CREATE_LEAVE: "Create_Leave",
  EDIT_LEAVE: "Edit_Leave",
  DELETE_LEAVE: "Delete_Leave",
  VIEW_LEAVE: "View_Leave",
  CREATE_OUTLET: "Create_Outlet",
  EDIT_OUTLET: "Edit_Outlet",
  DELETE_OUTLET: "Delete_Outlet",
  VIEW_OUTLET: "View_Outlet",
  CREATE_PRODUCT: "Create_Product",
  EDIT_PRODUCT: "Edit_Product",
  DELETE_PRODUCT: "Delete_Product",
  VIEW_PRODUCT: "View_Product"
};


export const membersAllPermissions = [
  { label: "Super Admin (All Permissions including Master)", value: "Super_Admin" },
  { label: "Reporting Manager", value: "Reporting_Manager" },
  { label: "Manager", value: "Manager" },
  { label: "View Manager", value: "View_Manager" },
  { label: "Create Manager", value: "Create_Manager" },
  { label: "Edit Manager", value: "Edit_Manager" },
  { label: "Delete Manager", value: "Delete_Manager" },
  { label: "Client FMCG", value: "ClientFMCG" },
  { label: "Client", value: "Client" },
  { label: "View Beet", value: "View_Beet" },
  { label: "Create Beet", value: "Create_Beet" },
  { label: "Edit Beet", value: "Edit_Beet" },
  { label: "Delete Beet", value: "Delete_Beet" },
  { label: "View Expense", value: "View_Expense" },
  { label: "Create Expense", value: "Create_Expense" },
  { label: "Edit Expense", value: "Edit_Expense" },
  { label: "Delete Expense", value: "Delete_Expense" },
  { label: "View Leave", value: "View_Leave" },
  { label: "Create Leave", value: "Create_Leave" },
  { label: "Edit Leave", value: "Edit_Leave" },
  { label: "Delete Leave", value: "Delete_Leave" },
  { label: "View Outlet", value: "View_Outlet" },
  { label: "Create Outlet", value: "Create_Outlet" },
  { label: "Edit Outlet", value: "Edit_Outlet" },
  { label: "Delete Outlet", value: "Delete_Outlet" },
  { label: "View Product", value: "View_Product" },
  { label: "Create Product", value: "Create_Product" },
  { label: "Edit Product", value: "Edit_Product" },
  { label: "Delete Product", value: "Delete_Product" }
];


export const permissionEnum = [
  { label: "Super_Admin", value: 0 },
  { label: "Reporting_Manager", value: 1 },
  { label: "Manager", value: 2 },
  { label: "Create_Manager", value: 3 },
  { label: "Edit_Manager", value: 4 },
  { label: "Delete_Manager", value: 5 },
  { label: "View_Manager", value: 6 },
  { label: "ClientFMCG", value: 7 },
  { label: "Client", value: 8 },
  { label: "Create_Beet", value: 9 },
  { label: "Edit_Beet", value: 10 },
  { label: "Delete_Beet", value: 11 },
  { label: "View_Beet", value: 12 },
  { label: "Create_Expense", value: 13 },
  { label: "Edit_Expense", value: 14 },
  { label: "Delete_Expense", value: 15 },
  { label: "View_Expense", value: 16 },
  { label: "Create_Leave", value: 17 },
  { label: "Edit_Leave", value: 18 },
  { label: "Delete_Leave", value: 19 },
  { label: "View_Leave", value: 20 },
  { label: "Create_Outlet", value: 21 },
  { label: "Edit_Outlet", value: 22 },
  { label: "Delete_Outlet", value: 23 },
  { label: "View_Outlet", value: 24 },
  { label: "Create_Product", value: 25 },
  { label: "Edit_Product", value: 26 },
  { label: "Delete_Product", value: 27 },
  { label: "View_Product", value: 28 },
];
