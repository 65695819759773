import { useSelector } from "react-redux";

// const CredId = useSelector((state) => state.Cred.sub)

export const ClientFMCGMenu = [
  {
    name: "Inventory",
    routerLink: ["/"],
    identifier: "Products",
    iconClass: "icofont-box",
    breadcrumbMessage: "",
    isCategory: false,
    isApp: true,
    children: [
      {
        name: "Inventory",
        routerLink: [""],
        identifier: "Inventory",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
    ],
  },
  {
    name: "Sales",
    routerLink: [""],
    identifier: "Sales",
    iconClass: "icofont-chart-histogram",
    breadcrumbMessage: "",
    isCategory: false,
    isApp: true,
    children: [
      {
        name: "Primary Sales",
        routerLink: [`primarySales`],
        identifier: "Primary Sales",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      {
        name: "Secondary Sales",
        routerLink: ["secondarySales"],
        identifier: "Secondary Sales",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      {
        name: "Return Sales",
        routerLink: ["salesReturn"],
        identifier: "Sales Return",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
    ],
  },
];
