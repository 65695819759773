import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useBeetApiHook } from "../../../hooks/beetHook";
import DataTable from "react-data-table-component";

function DoctorsModal({
    isModal,
    setIsModal,
    selectedBeet,
    doctorsList,
    setDoctorsList
}) {
    const {
        getAllDoctorsByBeetId
    } = useBeetApiHook()
    const [selectedDoctors, setSelectedDoctors] = useState([]);

    function AddDoctorToList(id){
        setSelectedDoctors((prev) => [...prev, id])
    }

    console.log("selected beat :", selectedBeet)
    async function getAllBeatDoctor(){
        if(!selectedBeet.id) return
        try {
            const resp = await getAllDoctorsByBeetId(selectedBeet.id);
            setDoctorsList(resp)
        } catch (error) {
            Swal.fire({
                text: "Can't fetch doctors! please try after some time",
                title: "Something went wrong",
                "icon": "warning",
                "timer": 2000
            })
            console.log("Error : unable to fetch all doctors", error)
        }
    }

    // useEffect(()=> {
    //     getAllBeatDoctor()
    // }, [selectedBeet.id]);

      let column = [
        {
            name: <span className="text-wrap"></span>,
            selector: (row) => row.outletName,
            sortable: true,
            cell: (row) => (
              <input
                className="checkbox"
                type="checkbox"
                value={false}
                onChange={() => AddDoctorToList(row.id)}
              />
            ),
            width: '50px', // Set width for checkbox column
          },
        {
          name: <span className="text-wrap">OUTLET NAME</span>,
          selector: (row) => row.outletName,
          sortable: true,
          cell: (row) => (
            <p className="fw-bold text-secondary text-wrap">
              {row?.outletName + " " + "(" + row.outletType + ")"}
            </p>
          ),
        },
        {
          name: <span className="text-wrap">OWNER NAME</span>,
          selector: (row) => row.ownerName,
          sortable: true,
          cell: (row) => (
            <p className="fw-bold text-secondary text-wrap">{row.ownerName}</p>
          ),
        },
    
        {
          name: <span className="text-wrap">GST NUMBER</span>,
          selector: (row) => row.gstNumber,
          sortable: true,
          cell: (row) => (
            <p className="fw-bold text-secondary text-wrap">{row.gstNumber}</p>
          ),
        },
    
        {
          name: <span className="text-wrap">OWNER CONTACT</span>,
          selector: (row) => row.mobile,
          sortable: true,
          cell: (row) => (
            <p className="fw-bold text-secondary text-wrap">{row.mobile}</p>
          ),
        },
      ];

  return (
    <Modal
      onHide={() => setIsModal(false)}
      show={isModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Doctors/Outlets List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{selectedBeet?.beet} - {selectedBeet?.address}</h4>
        <DataTable
          title={"Selected beat outlets"}
          columns={column}
          data={selectedBeet?.outlets}
          defaultSortField="title"
          pagination
          selectableRows={false}
          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
          highlightOnHover={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-primary" onClick={() => setIsModal(false)}>Apply</Button>
        <Button className=" btn-secondary" onClick={() => setIsModal(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DoctorsModal;
