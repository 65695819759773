import { useSelector } from "react-redux";
import { CLIENT_TYPE } from "./ClientFMCGMemberMenu";
import { permissionIds } from "../../constants/constants";

export default function useMemberMenu() {
  const CredId = useSelector((state) => state.Cred.sub);
  const { memberPermissions } = useSelector((state) => state.Permission);

  // Helper function to check permissions
  const hasPermission = (permissions) => {
    return memberPermissions.some((item) => permissions.includes(item));
  };

  // Expense menu item
  const expenseMenu = hasPermission([
    permissionIds.CREATE_EXPENSE,
    permissionIds.EDIT_EXPENSE,
    permissionIds.DELETE_EXPENSE,
    permissionIds.VIEW_EXPENSE,
  ])
    ? {
        name: "Expenses",
        routerLink: [`expenses/${CredId}`],
        identifier: "Expenses",
        iconClass: "icofont-ui-calculator",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      }
    : null;

  // Leave menu item
  const leaveMenu = hasPermission([
    permissionIds.CREATE_LEAVE,
    permissionIds.EDIT_LEAVE,
    permissionIds.DELETE_LEAVE,
    permissionIds.VIEW_LEAVE,
  ])
    ? {
        name: "Leave Requests",
        routerLink: [`leave-request/${CredId}`],
        identifier: "leave-request",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      }
    : null;

  // Beat menu item
  const beatMenu = hasPermission([
    permissionIds.CREATE_BEET,
    permissionIds.EDIT_BEET,
    permissionIds.DELETE_BEET,
    permissionIds.VIEW_BEET,
  ])
    ? {
        name: CLIENT_TYPE === "CLIENT_FMCG" ? "Beat" : "Routes",
        routerLink:
          CLIENT_TYPE === "CLIENT_FMCG"
            ? [`beets/${CredId}`]
            : [`routes/${CredId}`],
        identifier: "beats",
        iconClass: "icofont-location-pin",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: true,
        children: [],
      }
    : null;

  const MemberMenu = [
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Member Map",
          routerLink: [""],
          identifier: "membermap",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Members",
          routerLink: [`member/${CredId}`],
          identifier: "members",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Tour Plan",
          routerLink: [`members-scheduler/${CredId}`],
          identifier: "member scheduler",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        expenseMenu,
        {
          name: "Attendance",
          routerLink: [`attendance/${CredId}`],
          identifier: "Attendance",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        leaveMenu,
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ].filter(Boolean),
    },
    {
      name: "Our Customers",
      routerLink: ["/"],
      identifier: "clients",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Customers (FMCG)" : "Doctors",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`clientsFmcg/${CredId}`]
              : [`doctors/${CredId}`],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
        beatMenu,
      ].filter(Boolean),
    },
    {
      name: "Inventory",
      routerLink: [""],
      identifier: "Products",
      iconClass: "icofont-box",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Inventory",
          routerLink: ["inventory"],
          identifier: "Inventory",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Sales",
      routerLink: [""],
      identifier: "Sales",
      iconClass: "icofont-chart-histogram",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Primary Sales",
          routerLink: [`primarySales/${CredId}`],
          identifier: "Primary Sales",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Secondary Sales",
          routerLink: [`secondarySales/${CredId}`],
          identifier: "Secondary Sales",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
  ];

  return { MemberMenu };
}
