import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { apiGetAllBeetLogByVisitDate } from "../../../api/beet-journey/beet-journey-logs";
import {
  calculateTotalTime,
  convertSecondsToTimeFormat,
  getDateFormat,
  getTimeFormat,
} from "../../../helper/date-functions";
import { utils, writeFile } from "xlsx";
import { useNavigate } from "react-router-dom";
import { useIsFMCG } from "../../../helper/isManager";
import {
  apiGetAllPatchLogByVisitDate,
  apiGetAllPatchNotCreatedByVisitDate,
} from "../../../api/patch-journey";

function fn_refinedBeetLogByMember(data, isFMCG) {
  let memberLogMap = new Map();
  data.forEach((log) => {
    const memberInfo = log.memberGetDto;
    const beetInfo = log.beet;
    const outletInfo = log.outletGetDto;
    if (!memberLogMap.has(memberInfo.id)) {
      memberLogMap.set(memberInfo.id, {
        dcrDate: log.visitDate,
        employeeName: memberInfo.firstName + " " + memberInfo.lastName,
        employeeCode: memberInfo?.employeeId,
        state: beetInfo?.state ?? "N/A",
        headQtr: beetInfo?.address,
        designation: memberInfo?.designationName ?? "N/A",
        divisionName:
          memberInfo?.divisions?.map((e) => e.divisionName)?.join(" ") ?? "N/A",
        dayPlanDate: log.visitDate,
        checkinTime: log?.checkIn ?? "N/A",
        routeAddress: beetInfo?.address,
        workingType: log?.workingWith,
        workWith: log?.workingWithMemberDtoList
          ? log?.workingWithMemberDtoList
              ?.map((e) => `${e.memberName},`)
              ?.join(" ")
          : "N/A",
        plannedRoute: "N/A",
        workingArea: "N/A",
        firstCallTime: log?.checkIn ?? "N/A",
        lastCallTime: log?.checkOut ?? "N/A",
        wh: log?.totalTime ?? 0,
        submitAreaLastCall: outletInfo?.outletName,
        submitAreaLatLong: log?.checkoutAddress ?? "N/A",
        leaveTime: "N/A",
        submitDate: log.visitDate,
        gpsKm: (log?.totalDistance ?? 0) / 1000,
        totalCall: 0,
        completedCall: 0,
        missedCall: 0,
        totalDrCall: 0,
        totalDrCompletedCall: 0,
        totalDrMissCall: 0,
        totalChemistCall: 0,
        totalChemistCompletedCall: 0,
        totalChemistMissedCall: 0,
      });
    }
    let memberLog = memberLogMap.get(memberInfo.id);
    if (
      log.checkIn &&
      (memberLog.firstCallTime === "N/A" ||
        log.checkIn < memberLog.firstCallTime)
    ) {
      memberLog.firstCallTime = log.checkIn;
    }
    if (
      log.checkOut &&
      (memberLog.lastCallTime === "N/A" ||
        log.checkOut > memberLog.lastCallTime)
    ) {
      memberLog.lastCallTime = log.checkOut;
    }
    memberLog.totalCall += 1;

    if (isFMCG) {
      if (log.beetJourneyPlanStatus === "Completed") {
        memberLog.completedCall += 1;
      } else {
        memberLog.missedCall += 1;
      }
    } else {
      if (log.isDoctor) {
        memberLog.totalDrCall += 1;
      } else {
        memberLog.totalChemistCall += 1;
      }
      if (
        log.beetJourneyPlanStatus === "Completed" ||
        log.doctorJourneyPlanStatus === "Completed"
      ) {
        memberLog.completedCall += 1;
        memberLog[
          log.isDoctor ? "totalDrCompletedCall" : "totalChemistCompletedCall"
        ] += 1;
      } else {
        memberLog.missedCall += 1;
        memberLog[
          log.isDoctor ? "totalDrMissCall" : "totalChemistMissedCall"
        ] += 1;
      }
    }
    memberLog.wh += log?.totalTime ?? 0;
    memberLog.gpsKm += (log?.totalDistance ?? 0) / 1000;
  });

  let returnArray = [];
  memberLogMap.forEach((val, key) => {
    returnArray.push({
      ...val,
      id: key,
    });
  });
  return returnArray;
}

function fn_refinedPatchNotCreated(data, visitDate) {
  return data.map((log) => {
    return {
      memberName: `${log?.firstName} ${log.lastName}`,
      memberId: log.employeeId,
      email: log.email,
      isLeaveToday: log.leaveToday,
      visitDate,
      id: Math.random() + Date.now(),
    };
  });
}

export const exportToExcel = (data, columns, sheetName, excelName) => {
  const formattedData = data.map((row) =>
    columns.reduce((acc, column) => {
      acc[column.name] = column.selector(row);
      return acc;
    }, {})
  );
  const ws = utils.json_to_sheet(formattedData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, sheetName);
  writeFile(wb, excelName);
};

export const useBeetReport = () => {
  const [loading, setLoading] = useState(false);
  const [allMemberBeetLogReport, setAllMemberBeetLogReport] = useState([]);
  const [allMemberWithoutPlan, setAllMemberWithoutPlan] = useState([]);
  const [filterMemberId, setFilterMemberId] = useState([]);
  const [activeTabReport, setActiveReportTab] = useState("plan-created");
  const [noPlanFilterMemberId, setNoPlanFilterMemberId] = useState([]);
  const isFMCG = useIsFMCG();
  const navigate = useNavigate();
  async function helperGetCall(visitDate) {
    setLoading(true);
    try {
      const apiResp = isFMCG
        ? await apiGetAllBeetLogByVisitDate(visitDate)
        : await apiGetAllPatchLogByVisitDate(visitDate);
      let data = isFMCG
        ? apiResp
        : [
            ...apiResp.bjpReportResponseList,
            ...apiResp.doctorReportResponseList.map((e) => ({
              ...e,
              isDoctor: true,
            })),
          ];
      const refinedBeetLogByMember = fn_refinedBeetLogByMember(data, isFMCG);
      setAllMemberBeetLogReport(refinedBeetLogByMember);

      // Below call for member who haven't created plan

      console.clear();
      const not_createdResp = await apiGetAllPatchNotCreatedByVisitDate(
        visitDate
      );

      setAllMemberWithoutPlan(
        fn_refinedPatchNotCreated(not_createdResp ?? [], visitDate)
      );
    } catch (error) {
      console.log(error);
      Swal.fire(
        "Something went wrong",
        error?.response?.data?.message ?? "Can't Fetch Necessary data"
      );
    }
    setLoading(false);
  }

  const columns = useMemo(() => {
    let templateCol = [
      {
        name: "DCR DATE",
        selector: (row) => row.dcrDate,
        sortable: true,
        width: "120px",
      },
      {
        name: "Employee Name",
        selector: (row) => row.employeeName,
        sortable: true,
        width: "200px",
      },
      {
        name: "Employee Code",
        selector: (row) => (
          <p
            className="text-cyan text-decoration-underline"
            style={{ cursor: "pointer" }}
          >
            {row.employeeCode}
          </p>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "State",
        selector: (row) => row.state,
        sortable: true,
        width: "150px",
      },
      { name: "Head Qtr", selector: (row) => row.headQtr, width: "300px" },
      {
        name: "Designation",
        selector: (row) => row.designation,
        width: "150px",
      },
      {
        name: "Division Name",
        selector: (row) => row.divisionName,
        width: "150px",
      },
      {
        name: "Day Plan Date",
        selector: (row) => row.dayPlanDate,
        width: "150px",
      },
      {
        name: "Checkin Time",
        selector: (row) =>
          row.firstCallTime != "N/A" ? getTimeFormat(row.firstCallTime) : "N/A",

        width: "150px",
      },
      {
        name: "Route Address",
        selector: (row) => row.routeAddress,
        wrap: true,
        width: "350px",
      },
      {
        name: "Working Type",
        selector: (row) => row.workingType,
        width: "150px",
      },
      { name: "Work With", selector: (row) => row.workWith, width: "200px" },
      {
        name: "Planned Route",
        selector: (row) => row.plannedRoute,
        width: "150px",
      },
      {
        name: "First Call Time",
        selector: (row) =>
          row.firstCallTime != "N/A" ? getTimeFormat(row.firstCallTime) : "N/A",
        width: "150px",
      },
      {
        name: "Last Call Time",
        selector: (row) =>
          row.lastCallTime != "N/A" ? getTimeFormat(row.lastCallTime) : "N/A",
        width: "150px",
      },
      {
        name: "WH",
        // selector: (row) => convertSecondsToTimeFormat(row.wh || 0),
        selector: (row) => {
          // Check if firstCallTime and lastCallTime are not "N/A" and are valid date strings
          const isValidDate = (dateString) => {
            const date = new Date(dateString);
            return !isNaN(date.getTime()); // Returns true if date is valid
          };

          if (
            row.firstCallTime !== "N/A" &&
            row.lastCallTime !== "N/A" &&
            isValidDate(row.firstCallTime) &&
            isValidDate(row.lastCallTime)
          ) {
            return calculateTotalTime(row.firstCallTime, row.lastCallTime);
          } else {
            return "N/A";
          }
        },
        width: "100px",
      },

      {
        name: `Submit Area Last Call (${isFMCG ? "Outlet" : "Chemist"})`,
        selector: (row) => row.submitAreaLastCall,
        width: "250px",
      },
      {
        name: "Submit Area",
        selector: (row) => row.submitAreaLatLong,
        width: "780px",
      },
      {
        name: "EOD Time",
        selector: (row) =>
          row.lastCallTime != "N/A" ? getTimeFormat(row.lastCallTime) : "N/A",
      },
      {
        name: "Total Call",
        selector: (row) => `${row.totalCall}`,
        width: "100px",
        center: true,
      },
      {
        name: "Completed Call",
        selector: (row) => row.completedCall,
        width: "150px",
        center: true,
      },
      {
        name: "Missed Call",
        selector: (row) => row.missedCall,
        width: "100px",
        center: true,
      },
      {
        name: "GPS KM",
        selector: (row) => `${row.gpsKm?.toFixed(2) ?? 0} KM`,
        width: "100px",
        center: true,
      },
    ];

    templateCol.splice(
      templateCol.length - 1,
      0,
      {
        name: "Total Dr Call",
        selector: (row) => row.totalDrCall,
        width: "140px",
        center: true,
      },
      {
        name: "Total Dr Completed",
        selector: (row) => row.totalDrCompletedCall,
        width: "160px",
        center: true,
      },
      {
        name: "Total Dr Missed",
        selector: (row) => row.totalDrMissCall,
        width: "140px",
        center: true,
      },
      {
        name: "Total Chemist Call",
        selector: (row) => row.totalChemistCall,
        width: "150px",
        center: true,
      },
      {
        name: "Chemist Completed",
        selector: (row) => row.totalChemistCompletedCall,
        width: "170px",
        center: true,
      },
      {
        name: "Chemist Missed",
        selector: (row) => row.totalChemistMissedCall,
        width: "170px",
        center: true,
      }
    );

    return templateCol;
  }, [allMemberBeetLogReport]);

  useEffect(() => {
    helperGetCall(getDateFormat(new Date()));
  }, []);

  const filterDataMemberBeetLog = useMemo(() => {
    if (!filterMemberId || filterMemberId.length === 0)
      return allMemberBeetLogReport;

    return allMemberBeetLogReport.filter((log) =>
      filterMemberId.includes(log.id)
    );
  }, [filterMemberId, allMemberBeetLogReport]);

  const noPlanFilterMemberBeetLog = useMemo(() => {
    if (!noPlanFilterMemberId || noPlanFilterMemberId.length === 0)
      return allMemberWithoutPlan;

    return allMemberWithoutPlan.filter((log) =>
      noPlanFilterMemberId.includes(log.id)
    );
  }, [noPlanFilterMemberId, allMemberWithoutPlan]);

  const plan_not_created_col = useMemo(() => {
    return [
      {
        name: "DCR DATE",
        selector: (row) => row.visitDate,
        sortable: true,
        width: "120px",
      },
      {
        name: "Member Name",
        selector: (row) => row.memberName,
        sortable: true,
        width: "200px",
      },
      {
        name: "Member ID",
        selector: (row) => row.memberId,
        sortable: true,
        width: "150px",
      },
      {
        name: "Is Leave",
        selector: (row) => (row.isLeaveToday ? "Yes" : "No"),
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
    ];
  }, [allMemberWithoutPlan]);

  const planNotCreatedConditionalRowStyles = [
    {
      when: (row) => row.isLeaveToday,
      style: {
        backgroundColor: "#d4edda",
      },
    },
  ];

  return {
    columns,
    plan_not_created_col,
    loading,
    allMemberBeetLogReport,
    planNotCreatedConditionalRowStyles,
    allMemberWithoutPlan,
    helperGetCall,
    filterMemberId,
    setFilterMemberId,
    filterDataMemberBeetLog,
    onRowClicked: (row) =>
      navigate("/beet-report-member-detail", {
        state: row,
      }),
    isFMCG,
    exportToExcel: () =>
      exportToExcel(
        allMemberBeetLogReport,
        columns,
        "Journey Report",
        "Journey_Report.xlsx"
      ),
    exportToExcelPlanNotCreated: () =>
      exportToExcel(
        allMemberWithoutPlan,
        plan_not_created_col,
        "Journey Not Cred Report",
        "Journey_not_created_Report.xlsx"
      ),
    activeTabReport,
    setActiveReportTab,
    noPlanFilterMemberId,
    setNoPlanFilterMemberId,
    noPlanFilterMemberBeetLog,
  };
};
