import React, { useEffect } from "react";
import PageHeader from "../../../components/common/PageHeader";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import useAllOrdersReportHook from "./useAllOrdersReportHook";
import FilterComponent from "./FilterComponent";
import { useMemberHook } from "../../../hooks/memberHook";
import { useClientFMCGHook } from "../../../hooks/clientFMCGHook";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { clearReportData } from "../../../redux/features/reportSlice";
// import { GridExample } from "./reportTable";

function SalesReport() {
  const {
    loading,
    columns,
    selectedReportTypeLevel,
    selectedReportIdLevel,
    selectedReportCallLevel,
    setSelectedReportTypeLevel,
    setSelectedReportIdLevel,
    setSelectedReportCallLevel,
    getReportData
  } = useAllOrdersReportHook();
  const allData = useSelector((state) => state.Reports)

  const { allMembers, paginationData } = useSelector((state) => state.Member)
  const dispatch = useDispatch();
  const {
    get
  } = useMemberHook()
  const {
    getClientFMCG
  } = useClientFMCGHook()

  useEffect(() => {
    dispatch(clearReportData())
    get();
    getClientFMCG();
  },[])

  const exportToExcel = () => {
    // Dynamically define headers based on selectedReportTypeLevel
    const headers =
      selectedReportTypeLevel === "beet"
        ? [
            "Beet",
            "Address",
            "Postal Code",
            "Total Sales (₹)",
            "Total Orders",
          ]
        : [
            "Outlet Name",
            "Outlet Type",
            "Owner Name",
            "Total Sales (₹)",
            "Total Orders",
          ];

    try {
      console.log("selectedReportType", selectedReportTypeLevel)
      const rows = allData.content.map((row) => {
        console.log("row :", row)
        if (selectedReportTypeLevel.value === "beet") {
          return [
            row.beetRespForOrderDto.beet,
            row.beetRespForOrderDto.address,
            row.beetRespForOrderDto.postalCode,
            row.totalSales,
            row.totalOrder,
          ];
        } else if (selectedReportTypeLevel.value === "outlet") {
          return [
            row.outletRespForOrderDto.outletName,
            row.outletRespForOrderDto.outletType,
            row.outletRespForOrderDto.ownerName,
            row.totalSales,
            row.totalOrder,
          ];
        }
      });
  
      const wsData = [headers, ...rows];
      const ws = XLSX.utils.aoa_to_sheet(wsData);
  
      // Set column widths
      ws["!cols"] = headers.map(() => ({ width: 20 }));
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Orders Data");
      XLSX.writeFile(wb, "orders_data.xlsx");
    } catch (error) {
      console.log("Error :", error)
      Swal.fire(
        "Error Exporting Data",
        "Unable to Export Data",
        "error"
      )
    }
  };

  return (
    <div className="container-xxl">
      <PageHeader
        headerTitle="All Order Report"
        renderRight={() => (
          <Button onClick={exportToExcel}>Export To Excel</Button>
        )}
      />
      <FilterComponent
        selectedReportTypeLevel={selectedReportTypeLevel}
        setSelectedReportTypeLevel={setSelectedReportTypeLevel}
        selectedReportIdLevel={selectedReportIdLevel}
        setSelectedReportIdLevel={setSelectedReportIdLevel}
        selectedReportCallLevel={selectedReportCallLevel}
        setSelectedReportCallLevel={setSelectedReportCallLevel}
        getReportData={getReportData}
      />

      <div className="mt-3">
        <DataTable
          title="Sales Data"
          columns={columns}
          data={allData.content}
          responsive
          pagination
          progressPending={loading}
        /> 
        {/* // <GridExample /> */}
      </div>
    </div>
  );
}

export default SalesReport;
