import { useSelector } from "react-redux";
import { permissionIds } from "../../constants/constants";

export const CLIENT_TYPE = window.localStorage.getItem("CLIENT_TYPE");

export default function useClientFMCGMemberMenu() {
  const CredId = useSelector((state) => state.Cred.sub);
  const { memberPermissions } = useSelector((state) => state.Permission);

  // Helper function to check permissions
  function hasPermission(permissionList) {
    return memberPermissions.some((item) => permissionList.includes(item));
  }

  // Menu handlers
  function handleExpenseMenu() {
    if (
      memberPermissions.some(
        (item) =>
          item === permissionIds.CREATE_EXPENSE ||
          item === permissionIds.EDIT_EXPENSE ||
          item === permissionIds.DELETE_EXPENSE ||
          item === permissionIds.VIEW_EXPENSE
      )
    ) {
      return [
        {
          name: "Expenses",
          routerLink: [`expenses/${CredId}`],
          identifier: "Expenses",
          iconClass: "icofont-ui-calculator",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ];
    } else {
      return [];
    }
  }

  function handleLeaveMenu() {
    if (
      memberPermissions.some(
        (item) =>
          item === permissionIds.CREATE_LEAVE ||
          item === permissionIds.EDIT_LEAVE ||
          item === permissionIds.DELETE_LEAVE ||
          item === permissionIds.VIEW_LEAVE
      )
    ) {
      return [
        {
          name: "Leave Requests",
          routerLink: [`leave-request/${CredId}`],
          identifier: "leave-request",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ];
    } else {
      return [];
    }
  }

  function handleBeatMenu() {
    if (
      memberPermissions.some(
        (item) =>
          item === permissionIds.CREATE_BEET ||
          item === permissionIds.EDIT_BEET ||
          item === permissionIds.DELETE_BEET ||
          item === permissionIds.VIEW_BEET
      )
    ) {
      return [
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Beat" : "Routes",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`beets/${CredId}`]
              : [`routes/${CredId}`],
          identifier: "beats",
          iconClass: "icofont-location-pin",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
      ];
    } else {
      return [];
    }
  }

  function handleProductMenu() {
    if (
      hasPermission([
        permissionIds.CREATE_PRODUCT,
        permissionIds.EDIT_PRODUCT,
        permissionIds.DELETE_PRODUCT,
        permissionIds.VIEW_PRODUCT,
      ])
    ) {
      return [
        {
          name: "Products",
          routerLink: [""],
          identifier: "Master",
          iconClass: "icofont-ui-settings",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [
            {
              name: "Product",
              routerLink: ["productmaster"],
              identifier: "Product Master",
              iconClass: "",
              breadcrumbMessage: "",
              isCategory: false,
              isApp: false,
              children: [],
            },
          ],
        },
      ];
    }
    return [];
  }

  // Super Admin Menu
  const ClientFMCGSuperAdminMenu = [
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Member Map",
          routerLink: [""],
          identifier: "membermap",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Members",
          routerLink: [`member/${CredId}`],
          identifier: "members",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Expenses",
          routerLink: [`expenses/${CredId}`],
          identifier: "Expenses",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Attendance",
          routerLink: [`attendance/${CredId}`],
          identifier: "Attendance",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Leave Requests",
          routerLink: [`leave-request/${CredId}`],
          identifier: "leave-request",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Our Customers",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Customers (FMCG)" : "Clients",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`clientsFmcg/${CredId}`]
              : [`doctors/${CredId}`],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Beats" : "Routes",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`beets/${CredId}`]
              : [`routes/${CredId}`],
          identifier: "beets",
          iconClass: "icofont-location-pin",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
      ],
    },
  ];

  // Member Menu
  const ClientFMCGMemberMenu = [
    {
      name: "Sales",
      routerLink: [""],
      identifier: "Sales",
      iconClass: "icofont-chart-histogram",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Primary Sales",
          routerLink: [`primarySales/${CredId}`],
          identifier: "Primary Sales",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Secondary Sales",
          routerLink: [`secondarySales/${CredId}`],
          identifier: "Secondary Sales",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Inventory",
      routerLink: [""],
      identifier: "Products",
      iconClass: "icofont-box",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Inventory",
          routerLink: ["inventory"],
          identifier: "Inventory",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Member Map",
          routerLink: [""],
          identifier: "membermap",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Members",
          routerLink: [`member/${CredId}`],
          identifier: "members",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        ...handleExpenseMenu(),
        {
          name: "Attendance",
          routerLink: [`attendance/${CredId}`],
          identifier: "Attendance",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        ...handleLeaveMenu(),
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Our Customers",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Customers (FMCG)" : "Doctors",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`clientsFmcg/${CredId}`]
              : [`doctors/${CredId}`],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
        ...handleBeatMenu(),
      ],
    },
  ];

  const ProductMemberMenu = handleProductMenu();

  return {
    CLIENT_TYPE,
    ClientFMCGMemberMenu,
    ClientFMCGSuperAdminMenu,
    ProductMemberMenu,
  };
}
