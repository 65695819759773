import React from "react";
import { Route, Routes } from "react-router-dom";
import Members from "../Employee/Members";
import Expenses from "../Accounts/Expenses";
import Attendance from "../Employee/Attendance";
import Clients from "../Our Clients/Clients";
// import Schedules from "../Employee/schedules/Schedules";
import Profile from "../Employee/Profile";
import LeaveRequest from "../Employee/LeaveRequest";
import Status from "../Status/Status";
// import ProductPrice from "../Products/Price/ProductPrice";
import Inventory from "../Inventory/Inventory";
import ProductMaster from "../Products/Master/ProductMaster";
import PrimarySales from "../Sales/PrimarySales/PrimarySales";
import Beet from "../Beet/Beet";
import Tenant from "../Tenant/Tenant";
import RegionMaster from "../Master/RegionMaster";
import StateMaster from "../Master/StateMaster";
import CityMaster from "../Master/CityMaster";
import SecondarySales from "../Sales/SecondarySales/SecondarySales";
import Holidays from "../Employee/Holidays";
import Page404 from "../../components/Auth/Page404";
import FMCGClients from "../Our Clients/FMCGClients";
import ProtectedRoute from "./route-util";
import SalesReport from "../reports/sales-report";
import PartyReport from "../reports/party-report";
import TimeIntervalReports from "../reports/TimeIntervalReports";
import AllBeetOrderReport from "../reports/AllBeetOrdersReports";
import AllOutletOrderReport from "../reports/AllOutletOrdersReports";
import SaleDetailsPage from "../Sales/sales-detail/SaleDetailsPage";
import BeetJourneyReport from "../reports/BeetJourneyReports";
import MemberMapView from "../Employee/member-map-view/MemberMapView";
import AssignPermission from "../Master/Assignpermission/Assignpermission";
import BeetReport from "../reports/beet-report";
import BeetRangeReportMember from "../reports/beet-report/member-range-report";
import BeetLogDetail from "../reports/beet-report/beet-log-detail";
import Sample from "../Employee/Sample";
import ScheduleCalendar from "../Employee/schedules/ScheduleCalendar";
import BeetWiseReport from "../reports/beet-wise-report";
import OutletWiseReport from "../reports/beet-wise-report/outlet-wise-report";
function MemberRoutes() {
  return (
    <Routes>
      <Route path="member/:userId" element={<Members />} />
      <Route path="/" element={<MemberMapView />} />
      {/* <Route path="member-map" element={<MemberMapView />} /> */}
      <Route path="expenses/:userId" element={<Expenses />} />
      <Route path="assignpermission" element={<AssignPermission />} />
      <Route path="attendance/:userId" element={<Attendance />} />
      <Route path="/clientsFmcg/:userId" element={<FMCGClients />} />
      <Route path="/doctors/:userId" element={<FMCGClients />} />
      <Route path="clients" element={<Clients />} />
      <Route path="members-scheduler/:userId" element={<ScheduleCalendar />} />
      {/* <Route
        path="/member-schedule-plan/:userId"
        element={<ScheduleCalendar />}
      /> */}
      <Route path="member-profile" element={<Profile />} />
      <Route path="leave-request/:userId" element={<LeaveRequest />} />
      <Route path="status-report" element={<Status />} />
      <Route path="primarySales/:userId" element={<PrimarySales />} />
      <Route path="beets/:userId" element={<Beet />} />
      <Route path="routes/:userId" element={<Beet />} />
      <Route path="tenant" element={<Tenant />} />
      <Route path="secondarySales/:userId" element={<SecondarySales />} />
      <Route path="holidays" element={<Holidays />} />
      <Route path="*" element={<Page404 />} />
      <Route path="/sample/:userId" element={<Sample />} />

      {/* Protected routes */}

      <Route
        path="regionMaster"
        element={<ProtectedRoute element={<RegionMaster />} />}
      />
      <Route
        path="stateMaster"
        element={<ProtectedRoute element={<StateMaster />} />}
      />
      <Route
        path="cityMaster"
        element={<ProtectedRoute element={<CityMaster />} />}
      />
      <Route
        path="new-beet-report"
        element={<ProtectedRoute element={<BeetReport />} />}
      />
      <Route
        path="beet-wise-report"
        element={<ProtectedRoute element={<BeetWiseReport />} />}
      />
      <Route
        path="outlet-wise-report"
        element={<ProtectedRoute element={<OutletWiseReport />} />}
      />
      <Route
        path="beet-report-member-detail"
        element={<ProtectedRoute element={<BeetRangeReportMember />} />}
      />
      <Route
        path="beet-report-detail"
        element={<ProtectedRoute element={<BeetLogDetail />} />}
      />
      <Route
        path="sales-report"
        element={<ProtectedRoute element={<SalesReport />} />}
      />
      <Route
        path="party-report"
        element={<ProtectedRoute element={<PartyReport />} />}
      />
      <Route
        path="time-interval-report/:userId"
        element={<ProtectedRoute element={<TimeIntervalReports />} />}
      />
      <Route
        path="all-beet-order-report/:userId"
        element={<ProtectedRoute element={<AllBeetOrderReport />} />}
      />
      <Route
        path="all-outlet-order-report/:userId"
        element={<ProtectedRoute element={<AllOutletOrderReport />} />}
      />
      <Route
        path="beet-journey-report/:userId"
        element={<ProtectedRoute element={<BeetJourneyReport />} />}
      />
      {/* <Route
        path="productprice"
        element={<ProtectedRoute element={<ProductPrice />} />}
      /> */}
      {/* <Route
        path="productmaster"
        element={<ProtectedRoute element={<ProductMaster />} />}
      /> */}
      <Route path="inventory" element={<Inventory />} />
      <Route path="productmaster" element={<ProductMaster />} />
      <Route path="sale-detail" element={<SaleDetailsPage />} />
    </Routes>
  );
}

export default MemberRoutes;
