import React, { useState } from "react";
import Loading from "../../../components/UI/Loading";
import MemberDataTable from "./MemberDataTable";

function Schedules() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <Loading color="black" animation={"border"} />
      ) : (
        <div className="container-xxl" style={{height: "100%"}}>
         <MemberDataTable />
        </div>
      )}
    </>
  );
}

export default Schedules;
