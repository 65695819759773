import { useSelector } from "react-redux";
import { CLIENT_TYPE } from "./ClientFMCGMemberMenu";

export default function useMasterMenu() {
  const CredId = useSelector((state) => state.Cred.sub);

  const MasterMenu = [
    {
      name: "Master",
      routerLink: [""],
      identifier: "Master",
      iconClass: "icofont-ui-settings",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Region Master",
          routerLink: ["regionMaster"],
          identifier: "Region Master",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "State Master",
          routerLink: ["stateMaster"],
          identifier: "State Master",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "City Master",
          routerLink: ["cityMaster"],
          identifier: "City Master",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Product",
          routerLink: ["productmaster"],
          identifier: "Product Master",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Assign Permission",
          routerLink: ["assignpermission"],
          identifier: "Accounts",
          //iconClass: "icofont-ui-calculator",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
      ],
    },
    {
      name: "Reports",
      routerLink: [""],
      identifier: "Reporting",
      iconClass: "icofont-clip-board",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "New Beet Report",
          routerLink: ["new-beet-report"],
          identifier: "N Beet",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
          isSideBarClosed:true
        },
        {
          name: "Beet Wise Report",
          routerLink: ["beet-wise-report"],
          identifier: "N Beet",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
          isSideBarClosed:true
        },
        {
          name: "Party Report",
          routerLink: ["party-report"],
          identifier: "Party Report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Sales Report",
          routerLink: ["sales-report"],
          identifier: "Sales Report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Beet Order Report",
          routerLink: [`all-beet-order-report/${CredId}`],
          identifier: "All Order Report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Outlet Order Report",
          routerLink: [`all-outlet-order-report/${CredId}`],
          identifier: "All Order Report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Time Invterval Reports",
          routerLink: [`time-interval-report/${CredId}`],
          identifier: "Outlet Order Report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        // {
        //   name: `${CLIENT_TYPE === "CLIENT_FMCG" ? "Beat" : "Route"} Journey Reports`,
        //   routerLink: [`beet-journey-report/${CredId}`],
        //   identifier: "Beet Journey Report",
        //   iconClass: "",
        //   breadcrumbMessage: "",
        //   isCategory: false,
        //   isApp: false,
        //   children: [],
        // },
      ],
    },
  ];

  return {
    MasterMenu,
  };
}
