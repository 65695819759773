// AttendanceIcon.js

import React, { useState } from "react";
import "../../assets/scss/changes/custom-changes.css";
import { getTimeFormat } from "../../helper/date-functions";
import Popup from "reactjs-popup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
function AttendanceIcon({ attendanceForDay }) {
  const [hoverCard, setHoverCard] = useState(false);

  const handleMouseOver = () => {
    setHoverCard(true);
  };

  const handleMouseOut = () => {
    setHoverCard(false);
  };
  const renderTooltip = (props) => (
    <Tooltip id="attendance-tooltip" {...props}>
      <div>
        {attendanceForDay?.isPresent ? (
          <div>
            {attendanceForDay.checkInTime && (
              <p>
                CheckIn :{" "}
                {getTimeFormat(new Date(attendanceForDay.checkInTime))}
              </p>
            )}
            {attendanceForDay.checkOutTime && (
              <p>
                CheckOut :{" "}
                {getTimeFormat(new Date(attendanceForDay.checkOutTime))}
              </p>
            )}
          </div>
        ) : (
          "No checkIn"
        )}
      </div>
    </Tooltip>
  );

  return (
    <td style={{ width: "50px" }}>
      {attendanceForDay.isPresent ? (
        <OverlayTrigger
          placement="top"
          overlay={renderTooltip}
          delay={{ show: 250, hide: 400 }}
        >
          <i className="icofont-check-circled text-success"></i>
        </OverlayTrigger>
      ) : (
        <i className="icofont-close-circled text-danger"></i>
      )}
    </td>
  );
}

export default AttendanceIcon;
