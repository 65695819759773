import { useSelector } from "react-redux";
import { useIsFMCG } from "../../../helper/isManager";
import { useEffect, useMemo, useState } from "react";
import { apiGetAllOrderBySalesLevelAndStartAndEndDate } from "../../../api/order/order-api";
import Swal from "sweetalert2";
import { getDateFormat } from "../../../helper/date-functions";
import { formatPriceINR } from "../../../helper/exportFunction";
import { exportToExcel } from "../beet-report/hook";
import { useNavigate } from "react-router-dom";

const fn_refine_beetWise = (data = []) => {
  let beetWiseMap = new Map();
  data.forEach((ord) => {
    let outletData = ord.outletRespForOrderDto;
    let beetData = ord.beetRespForOrderDto;
    if (
      !beetWiseMap.has(beetData?.id) &&
      beetData != null &&
      outletData != null
    ) {
      beetWiseMap.set(beetData.id, {
        beetName: beetData.beet,
        beetAddress: beetData.address,
        beetCity: beetData.city,
        beetState: beetData.state,
        beetPostalCode: beetData.postalCode,
        totalOrders: 0,
        totalOrderValueWithoutGst: ord.totalPrice ?? 0,
        totalOrderValueWithGst: ord.totalPriceWithGst ?? 0,
        outletWiseList: [
          {
            outletId: outletData?.id,
            outletName: outletData?.outletName,
            outletType: outletData?.outletType,
            ownerName: outletData?.ownerName,
            totalPrice: ord.totalPrice ?? 0,
            totalOrderValueWithGst: ord.totalPriceWithGst ?? 0,
          },
        ],
      });
    } else if (beetData != null && outletData != null) {
      let mapBeet = beetWiseMap.get(beetData.id);

      beetWiseMap.set(beetData.id, {
        ...mapBeet,
        totalOrderValueWithoutGst:
          (ord.totalPrice ?? 0) + mapBeet.totalOrderValueWithoutGst,
        totalOrderValueWithGst:
          (ord.totalPriceWithGst ?? 0) + mapBeet.totalOrderValueWithGst,
        totalOrders: mapBeet.totalOrders + 1,
        outletWiseList: [
          ...mapBeet.outletWiseList,
          {
            outletId: outletData?.id,
            outletName: outletData?.outletName,
            outletType: outletData?.outletType,
            ownerName: outletData?.ownerName,
            totalPrice: ord.totalPrice ?? 0,
            totalOrderValueWithGst: ord.totalPriceWithGst ?? 0,
          },
        ],
      });
    }
  });

  let returnArray = [];
  beetWiseMap.forEach((val, key) => {
    returnArray.push({
      ...val,
      id: key,
    });
  });

  return returnArray;
};

const fn_extract_unique_state = (data = []) => {
  let uniqueState = new Map();
  data.forEach((beet) => {
    if (!uniqueState.has(beet.beetState)) {
      uniqueState.set(beet.beetState, "--");
    }
  });

  let returnArray = [];
  uniqueState.forEach((val, key) => {
    returnArray.push({
      label: key,
      value: key,
    });
  });
  return returnArray;
};

const fn_extract_unique_city = (data = []) => {
  let uniqueState = new Map();
  data.forEach((beet) => {
    if (!uniqueState.has(beet.beetCity)) {
      uniqueState.set(beet.beetCity, beet.beetState);
    }
  });

  let returnArray = [];
  uniqueState.forEach((val, key) => {
    returnArray.push({
      label: key,
      value: key,
    });
  });
  return returnArray;
};
export const useBeetWiseReport = () => {
  const isFMCG = useIsFMCG();
  const Cred = useSelector((state) => state.Cred);
  const [allBeetWiseReport, setAllBeetWiseReport] = useState([]);
  const [fetchReportProgress, setFetchReportProgress] = useState(true);
  const navigate = useNavigate();
  const [allUniqueStateList, setAllUniqueStateList] = useState([]);
  const [allUniqueCityList, setAllUniqueCityList] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  async function helperCall(startDate, endDate) {
    setFetchReportProgress(true);
    try {
      const resp = await apiGetAllOrderBySalesLevelAndStartAndEndDate(
        "STOCKIST",
        startDate,
        endDate
      );
      let refineBeetWiseData = fn_refine_beetWise(resp);
      let uniqueStateList = fn_extract_unique_state(refineBeetWiseData);
      let uniqueCityList = fn_extract_unique_city(refineBeetWiseData);
      setAllUniqueStateList(uniqueStateList);
      setAllUniqueCityList(uniqueCityList);
      setAllBeetWiseReport(fn_refine_beetWise(resp));
    } catch (error) {
      console.log(error);
      Swal.fire(
        "Something went wrong",
        error?.response?.data?.message ?? "Can't Fetch Necessary data"
      );
    }
    setFetchReportProgress(false);
  }

  useEffect(() => {
    if (!allBeetWiseReport.length > 0) {
      let startDate = new Date();
      startDate.setDate(1);
      let endDate = new Date();
      endDate.setDate(30);
      helperCall(
        getDateFormat(startDate).concat("T00:00:00.000Z"),
        getDateFormat(endDate).concat("T00:00:00.000Z")
      );
    }
  }, []);

  const columns = useMemo(() => {
    let template = [
      {
        name: "State",
        selector: (row) => row.beetState,
        width: "150px",
      },
      {
        name: "City",
        selector: (row) => row.beetCity,
        width: "150px",
      },
      {
        name: "Postal Code",
        selector: (row) => row.beetPostalCode,
        width: "150px",
      },
      {
        name: "Beet Name",
        selector: (row) => row.beetName,
        width: "210px",
      },
      {
        name: "Total Orders",
        selector: (row) => row.totalOrders,
        width: "150px",
      },
      {
        name: "Excl. GST",
        selector: (row) =>
          formatPriceINR(row.totalOrderValueWithGst?.toFixed(2) ?? 0),
        width: "150px",
      },
      {
        name: "Incl. GST",
        selector: (row) =>
          formatPriceINR(row.totalOrderValueWithoutGst?.toFixed(2) ?? 0),
        width: "150px",
      },
      {
        name: "Beet Address",
        selector: (row) => row.beetAddress,
        width: "250px",
      },
    ];

    return template;
  }, [allBeetWiseReport]);

  const filterBeetWiseReport = useMemo(() => {
    if (selectedCities.length > 0) {
      return allBeetWiseReport.filter((beet) =>
        selectedCities.includes(beet.beetCity)
      );
    } else if (selectedStates.length > 0) {
      return allBeetWiseReport.filter((beet) =>
        selectedStates.includes(beet.beetState)
      );
    } else {
      return allBeetWiseReport;
    }
  }, [allBeetWiseReport, selectedCities, selectedStates]);

  return {
    isFMCG,
    fetchReportProgress,
    filterBeetWiseReport,
    columns,
    helperCall,
    exportToExcelFn: () =>
      exportToExcel(
        allBeetWiseReport,
        columns,
        `${isFMCG ? "Beet" : "Patch"} wise report`,
        `${isFMCG ? "Beet" : "Patch"}_wise_Report.xlsx`
      ),
    allUniqueCityList,
    allUniqueStateList,
    selectedStates,
    selectedCities,
    setSelectedStates,
    setSelectedCities,
    onRowClicked: (row) =>
      navigate("/outlet-wise-report", {
        state: row,
      }),
  };
};
