import { useSelector } from "react-redux";
import { CLIENT_TYPE } from "./ClientFMCGMemberMenu";
import { useParams } from "react-router-dom";

export default function useMemberMenu() {
  const CredId = useSelector((state) => state.Cred.sub);

  const MemberMenu = [
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Member Map",
          routerLink: [""],
          identifier: "membermap",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Members",
          routerLink: [`member/${CredId}`],
          identifier: "members",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Attendance",
          routerLink: [`attendance/${CredId}`],
          identifier: "Attendance",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Leave Requests",
          routerLink: [`leave-request/${CredId}`],
          identifier: "leave-request",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Schedules",
          routerLink: [`members-scheduler/${CredId}`],
          identifier: "member scheduler",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        // {
        //   name: "DSR",
        //   routerLink: ["status-report"],
        //   identifier: "status report",
        //   iconClass: "",
        //   breadcrumbMessage: "",
        //   isCategory: false,
        //   isApp: false,
        //   children: [],
        // },
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Our Clients",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        // {
        //   name: "Clients",
        //   routerLink: ["clients"],
        //   identifier: "clients",
        //   iconClass: "icofont-users-alt-5",
        //   breadcrumbMessage: "",
        //   isCategory: false,
        //   isApp: true,
        //   children: []
        // },
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Clients (FMCG)" : "Doctors",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`clientsFmcg/${CredId}`]
              : [`doctors/${CredId}`],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
        {
          name: CLIENT_TYPE === "CLIENT_FMCG" ? "Beat" : "Routes",
          routerLink:
            CLIENT_TYPE === "CLIENT_FMCG"
              ? [`beets/${CredId}`]
              : [`routes/${CredId}`],
          identifier: "beats",
          iconClass: "icofont-location-pin",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: [],
        },
      ],
    },
    {
      name: "Inventory",
      routerLink: [""],
      identifier: "Products",
      iconClass: "icofont-box",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Inventory",
          routerLink: ["inventory"],
          identifier: "Inventory",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Sales",
      routerLink: [""],
      identifier: "Sales",
      iconClass: "icofont-chart-histogram",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Primary Sales",
          routerLink: [`primarySales/${CredId}`],
          identifier: "Primary Sales",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
        {
          name: "Secondary Sales",
          routerLink: [`secondarySales/${CredId}`],
          identifier: "Secondary Sales",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: [],
        },
      ],
    },
    {
      name: "Expenses",
      routerLink: [`expenses/${CredId}`],
      identifier: "Expenses",
      iconClass: "icofont-ui-calculator",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: false,
      children: [],
    },

    // {
    //   name: "Accounts",
    //   routerLink: [""],
    //   identifier: "Accounts",
    //   iconClass: "icofont-ui-calculator",
    //   breadcrumbMessage: "",
    //   isCategory: false,
    //   isApp: true,
    //   children: [
    //     {
    //       name: "Expenses",
    //       routerLink: [`expenses/${CredId}`],
    //       identifier: "Expenses",
    //       iconClass: "",
    //       breadcrumbMessage: "",
    //       isCategory: false,
    //       isApp: false,
    //       children: [],
    //     },
    //   ],
    // },
  ];

  return {
    MemberMenu,
  };
}
